export const bookingColumn = [
  {
    header: "Customer",
    name: "name",
    defaultFlex: 1,
  },
  {
    header: "Status",
    name: "status",
    defaultFlex: 1,
  },

  {
    header: "Amount",
    name: "amount",
    defaultFlex: 1,
  },
  {
    header: "Booking Date",
    name: "bookingDate",
    defaultFlex: 1,
  },
  {
    header: "Date",
    name: "date",
    defaultFlex: 1,
  },
  {
    header: "Time",
    name: "time",
    defaultFlex: 1,
  },
];

export const chrecheColumn = [
  {
    header: "Name",
    name: "name",
    defaultFlex: 1,
  },
  {
    header: "Location",
    name: "location",
    defaultFlex: 1,
  },

  {
    header: "Mobile",
    name: "mobile",
    defaultFlex: 1,
  },
  {
    header: "Verified",
    name: "verified",
    defaultFlex: 1,
  },
  {
    header: "Email",
    name: "email",
    defaultFlex: 1,
  },
];

export const usersColumn = [
  {
    header: "Name",
    name: "name",
    defaultFlex: 1,
  },
  {
    header: "Location",
    name: "location",
    defaultFlex: 1,
  },

  {
    header: "Mobile",
    name: "mobile",
    defaultFlex: 1,
  },
  {
    header: "Verified",
    name: "verified",
    defaultFlex: 1,
  },
  {
    header: "Type",
    name: "type",
    defaultFlex: 1.5,
  },
];

export const vetColumn = [
  {
    header: "Name",
    name: "name",
    defaultFlex: 1,
  },
  {
    header: "Mobile No",
    name: "mobile",
    defaultFlex: 1,
  },
  {
    header: "Timings",
    name: "availableHours",
    defaultFlex: 3.5,
  },
  {
    header: "Verified",
    name: "verified",
    defaultFlex: 1,
  },
  {
    header: "Location",
    name: "location",
    defaultFlex: 1,
  },
  {
    header: "Days of Operation",
    name: "daysOfOperation",
    defaultFlex: 4,
  },
];
