import styled from "styled-components";

export const StyledPrivacyPolicy = styled.div`
  width: 100%;

  flex-direction: column;
  display: flex;
  justify-content: center;
  .nav-main-con {
    width: 100%;
    background-color: #0066ba;
  }
  .privacy-content {
    padding: 50px 20px;
    width: 90%;
  }
`;
