export const ToolBarFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "blockquote",
  "code-block",
  "script",
  "indent",
  "direction",
  "size",
  "color",
  "background",
  "font",
  "clean",
];
