import styled from "styled-components";

export const TermsStyled = styled.div`
  width: 100%;
  height: 70vh;

  .terms-nav-con {
    width: 100%;
    background-color: rgba(0, 102, 186, 1);
  }
`;
