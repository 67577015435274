export const userFilter = [
  {
    name: "name",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "location",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "mobile",
    type: "number",
    operator: "contains",
    value: "",
  },
  {
    name: "verified",
    type: "string",
    operator: "contains",
    value: "",
  },
];

export const vetFilter = [
  {
    name: "name",
    type: "string",
    operator: "contains",
  },
  {
    name: "mobile",
    type: "string",
    operator: "contains",
  },
  {
    name: "verified",
    type: "string",
    operator: "contains",
  },
  {
    name: "location",
    type: "string",
    operator: "contains",
  },
  {
    name: "daysOfOperation",
    type: "string",
    operator: "contains",
  },
  {
    name: "availableHours",
    type: "string",
    operator: "contains",
  },
];

export const crecheFilter = [
  {
    name: "name",
    type: "string",
    operator: "contains",
  },
  {
    name: "mobile",
    type: "number",
    operator: "contains",
  },
  {
    name: "verified",
    type: "string",
    operator: "contains",
  },
  {
    name: "email",
    type: "string",
    operator: "contains",
  },
  {
    name: "location",
    type: "string",
    operator: "contains",
  },
];

export const bookingFilter = [
  {
    name: "name",
    type: "string",
    operator: "contains",
  },
  {
    name: "status",
    type: "string",
    operator: "contains",
  },
  {
    name: "amount",
    type: "number",
    operator: "gte",
  },
  {
    name: "date",
    type: "string",
    operator: "contains",
  },
  {
    name: "bookingDate",
    type: "string",
    operator: "contains",
  },
  {
    name: "time",
    type: "string",
    operator: "contains",
  },
];
